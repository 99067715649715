import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Menu from "./menu.js";
import Footer from "./footer.js";
import styles from "./homepage.module.css";
import { useTranslation } from "react-i18next";
import Bildjanne from "./assets/images/advertising_bild.webp";
import Bildjanne1 from "./assets/images/Nytt_projekt.webp";
import Bildjanne2 from "./assets/images/test2.webp";
import mobilimage from "./assets/images/bilden2-mindre.jpg";
import mobilimage1 from "./assets/images/bot3.webp";
import furnitureCase from "./assets/images/furniture-case2.png";
import timberCase from "./assets/images/truck.webp";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import ScrollToTopButton from "./ScrollToTop.js";
import { FaArrowDown } from "react-icons/fa";
import ContactForm from "./components/cases/ContactForm.js";

const Banner = ({ scrollToProjects }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.startBanner}>
      <div className={styles.huvudKontainer}>
        <h1
          className={`${styles.customtitle} ${styles.fadeIn}`}
          style={{ animationDelay: "0.5s" }}
        >
          {t("first-titel-home")}
        </h1>
        <h2
          className={`${styles.customtitle2} ${styles.fadeIn}`}
          style={{ animationDelay: "1s" }}
        >
          {t("first-titel-home1")}
        </h2>

        <Link
          to="/contact"
          className={`${styles.bannerButtonStyle} ${styles.fadeIn}`}
          style={{ animationDelay: "1.5s" }}
        >
          {t("bannerButtonText")}
          <svg
            className={styles.projectReadMoreArrow}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="14"
            height="14"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              d="M12 2l-1.41 1.41L17.17 10l-6.58 6.59L12 18l8-8-8-8z"
              className={styles.arrowPath}
            />
          </svg>
        </Link>

        <button
          className={`${styles.bannerButton2Style} ${styles.fadeIn}`}
          style={{ animationDelay: "1.5s" }}
          onClick={scrollToProjects}
        >
          {t("bannerButtonText2")}
          <svg
            className={styles.projectReadMoreArrow}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="14"
            height="14"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              d="M12 2l-1.41 1.41L17.17 10l-6.58 6.59L12 18l8-8-8-8z"
              className={styles.arrowPath}
            />
          </svg>
        </button>
      </div>
      <div className={styles["arrow-container"]}>
        <FaArrowDown className={styles["arrow-down"]} />
      </div>
    </div>
  );
};

const GrowthSection = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.growthSection}>
      <div className={styles.textContainer} data-aos="fade-up">
        <h2 className={styles.growthTitle}>{t("growth-title")}</h2>
        <p className={styles.growthDescription}>{t("growth-description")}</p>
        <Link to="/contact" className={styles.growthButton}>
          {t("contact-button-text")}
        </Link>
      </div>
    </div>
  );
};

function ServiceItem({ title, description, link, isSpecial, imageSrc }) {
  const serviceItemRef = useRef(null);

  const handleMouseMove = (e) => {
    const rect = serviceItemRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const bgDegree = (x / rect.width) * 85;
    serviceItemRef.current.style.background = `linear-gradient(${
      bgDegree + 45
    }deg, #192c3b, #6e8aa3)`;
  };

  const handleMouseOut = () => {
    serviceItemRef.current.style.background =
      "linear-gradient(45deg, #192c3b, #556a7c)";
  };

  return (
    <Link to={link} className={styles.serviceLink} data-aos="fade-up">
      <div
        className={styles.serviceItem}
        ref={serviceItemRef}
        onMouseMove={handleMouseMove}
        onMouseOut={handleMouseOut}
      >
        {isSpecial ? (
          <div className={styles.leftContent}>
            <div className={styles.serviceIcon}></div>
            <h3 className={styles.serviceTitle}>{title}</h3>
            <p className={styles.serviceDescription}>{description}</p>
          </div>
        ) : (
          <>
            <div className={styles.serviceIcon}></div>
            <h3 className={styles.serviceTitle}>{title}</h3>
            <p className={styles.serviceDescription}>{description}</p>
          </>
        )}
        <img src={imageSrc} alt={title} className={styles.serviceImage} />
      </div>
    </Link>
  );
}

const ServicesSection = () => {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <div className={styles.servicesSection}>
      <h2 className={styles.servicesTitle}>{t("ourServices")}</h2>
      <p className={styles.servicesDescriptionText}>
        {t("servicesDescription")}
      </p>
      <div className={styles.servicesGrid}>
        <ServiceItem
          title={t("service1-title")}
          description={t("service1-description")}
          link="/website"
          imageSrc={Bildjanne1}
        />
        <ServiceItem
          title={t("service2-title")}
          description={t("service2-description")}
          link="/ai"
          imageSrc={Bildjanne2}
        />
        <ServiceItem
          title={t("service3-title")}
          description={t("service3-description")}
          link="/advertisement"
          isSpecial={true}
          imageSrc={Bildjanne}
        />
      </div>
    </div>
  );
};

const ServicesSection2 = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.servicesSection2}>
      <div className={styles.titleContainer}>
        <h2 className={styles.servicesTitle2}>
          {t("ourServices2")
            .split("")
            .map((char, index) => (
              <span className={styles.letter} key={index}>
                {char}
              </span>
            ))}
        </h2>
        <p className={styles.servicesDescriptionText2}>
          {t("servicesDescription2")
            .split("")
            .map((char, index) => (
              <span className={styles.letter} key={index}>
                {char}
              </span>
            ))}
        </p>
      </div>
    </div>
  );
};

const projectsData = [
  {
    image: furnitureCase,
    titleKey: "project1Title",
    categoryKey: "project1Category",
    descriptionKey: "project1Description",
    textLink: "/case/e-commerce",
    categoryColor: "#ff8e68", // Example color
  },
  {
    image: mobilimage1,
    titleKey: "project2Title",
    categoryKey: "project2Category",
    descriptionKey: "project2Description",
    textLink: "/case/ai-bot",
    categoryColor: "#226c92", // Example color
  },
  {
    image: timberCase,
    titleKey: "project3Title",
    categoryKey: "project3Category",
    descriptionKey: "project3Description",
    textLink: "/case/landingpage",
    categoryColor: "#006b39", // Example color
  },
];

const Projects = React.forwardRef((props, ref) => {
  const { t } = useTranslation();

  return (
    <div ref={ref} className={styles.projectsContainer}>
      <ServicesSection2 />
      {projectsData.map((project, index) => (
        <div
          className={`${styles.project} ${
            index % 2 === 0 ? styles.projectNormal : styles.projectReversed
          }`}
          key={index}
        >
          <div className={styles.projectTextContainer}>
            <p
              className={styles.projectCategory}
              style={{ color: project.categoryColor }}
            >
              {t(project.categoryKey)}
            </p>
            <h2 className={styles.projectTitle}>{t(project.titleKey)}</h2>
            <p className={styles.projectDescription}>
              {t(project.descriptionKey)
                .split("\n")
                .map((line, idx) => (
                  <div key={idx} className={styles.descriptionLine}>
                    {line}
                  </div>
                ))}
            </p>
            <div className={styles.projectReadMoreWrapper}>
              <a href={project.textLink} className={styles.projectReadMore}>
                Läs mer
                <svg
                  className={styles.projectReadMoreArrow}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                  fill="currentColor"
                >
                  <path d="M12 2l-1.41 1.41L16.17 9H2v2h14.17l-5.58 5.59L12 18l8-8-8-8z" />
                </svg>
              </a>
            </div>
            {index < 2 && <div className={styles.projectBottomBorder}></div>}
          </div>
          <div
            className={styles.projectImageContainer}
            style={{ backgroundImage: `url(${project.image})` }}
          />
          {!project.image && <p>Image failed to load</p>}
        </div>
      ))}
    </div>
  );
});

const ContactForm1 = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.my5}>
      <div className={styles.container} data-aos="fade-up">
        <div
          className={`${styles.row} ${styles.dFlex} ${styles.alignItemsStretch}`}
        >
          <div className={`${styles.col} ${styles.lg6} ${styles.firstCol}`}>
            <div className={styles.cardShadow}>
              <div
                className={`${styles.cardBody} ${styles.dFlex} ${styles.flexColumn}`}
              >
                <h3 className={styles.cardTitle1}>Vart finns vi?</h3>
                <p className={styles.cardText1}>
                  Stockholm/Sundsvall, SE
                  <br />
                  info@saigah.com
                  <br />
                  +46 727 202 106
                </p>
                <div className={styles.mapResponsive}>
                  <iframe
                    title="location"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d130265.83887425221!2d17.817248407020944!3d59.32621310815854!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f763119640bcb%3A0xa80d27d3679d7766!2sStockholm!5e0!3m2!1sen!2sse!4v1717254257037!5m2!1sen!2sse"
                    style={{ border: 0 }}
                    width="800"
                    height="600"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.col} ${styles.lg6}`}>
            <div className={`${styles.h100} ${styles.cardShadow2}`}>
              <div
                className={`${styles.cardBody} ${styles.dFlex} ${styles.flexColumn}`}
              >
                <h3 className={styles.cardTitle}>Hör av dig till oss!</h3>
                <p className={styles.cardText}>
                  Använd gärna formuläret nedan för att kontakta oss. Vi
                  återkommer till dig så snart som möjligt.
                </p>
                <form>
                  <div className={`${styles.formGroup} ${styles.mb3}`}>
                    <input
                      type="text"
                      placeholder="Ditt namn"
                      className={styles.formControl}
                    />
                  </div>
                  <div className={`${styles.formGroup} ${styles.mb3}`}>
                    <input
                      type="email"
                      placeholder="E-post"
                      className={styles.formControl}
                    />
                  </div>
                  <div className={`${styles.formGroup} ${styles.mb3}`}>
                    <input
                      type="tel"
                      placeholder="Telefonnummer"
                      className={styles.formControl}
                    />
                  </div>
                  <div className={`${styles.formGroup} ${styles.mb3}`}>
                    <textarea
                      rows={2}
                      placeholder="Meddelande"
                      className={styles.formControl}
                    ></textarea>
                  </div>
                  <div
                    className={styles.inputGroup}
                    style={{ textAlign: "center" }}
                  >
                    <input
                      className={styles.submit}
                      type="submit"
                      value={t("submit")}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const BlogPostSection = () => {
  const [articles, setArticles] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const spaceId = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
    const accessToken = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;
    const endpoint = `https://graphql.contentful.com/content/v1/spaces/${spaceId}/`;

    const query = `
    {
      articlesForSaigahCollection(order: date_DESC, limit: 4) {
        items {
          sys {
            id
          }
          title
          slug
          date
          excerpt
          logo {
            url
          }
          categoriesCollection {
            items {
              category
            }
          }
        }
      }
    }
    `;

    window
      .fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ query }),
      })
      .then((response) => response.json())
      .then(({ data, errors }) => {
        if (errors) {
          console.error(errors);
          return;
        }
        setArticles(data.articlesForSaigahCollection.items);
      });

    window.scrollTo(0, 0);
  }, []);

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString)
      .toLocaleDateString("en-GB", options)
      .replace(/\//g, "-");
  };

  return (
    <section className={styles.latestPostsSection}>
      <h2 className={styles.latestPostsTitle}>{t("insikter")}</h2>
      <div className={styles.postsContainer}>
        {articles.map((article) => (
          <Link
            to={`/${article.slug}`}
            key={article.sys.id}
            className={styles.articleLink}
          >
            <div className={styles.postCard}>
              <img
                src={article.logo.url}
                alt={article.title}
                className={styles.postImage}
              />
              {article.categoriesCollection.items.length > 0 && (
                <span className={styles.postCategory}>
                  {article.categoriesCollection.items[0].category}
                </span>
              )}
              <div className={styles.postInfo}>
                <h3 className={styles.postTitle}>{article.title}</h3>
                <p className={styles.postDate}>{formatDate(article.date)}</p>

                <p className={styles.pageText}>
                  {article.excerpt.length > 50
                    ? `${article.excerpt.substring(0, 120)}...`
                    : article.excerpt}
                </p>
              </div>
              <div className={styles.learnMore}>
                {t("learnMore")}{" "}
                <span className={styles.arrow}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 12H19"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 5L19 12L12 19"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </section>
  );
};

const HomePage = () => {
  const { t } = useTranslation();
  const projectsRef = useRef(null);

  const scrollToProjects = () => {
    if (projectsRef.current) {
      projectsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }

    setIsLoaded(true);
  }, []);

  if (!isLoaded) {
    return null;
  }
  return (
    <div>
      <Helmet>
        <title>SAIGAH Webbyrå - Webblösningar för din affärstillväxt</title>
        <meta
          name="description"
          content="SAIGAH Webbyrå specialiserar sig på att utveckla hemsidor, innovativ marknadsföring och avancerade AI-chatbots för att driva din affärstillväxt. Vi kombinerar teknisk expertis och kreativa lösningar för att skapa en unik digital närvaro som engagerar och konverterar."
        />
        <meta
          name="keywords"
          content="digital solutions, web development, AI analytics, Website, digitala lösningar, webbutveckling, AI-analyser, hemsida"
        />

        <meta
          property="og:title"
          content="SAIGAH Webbyrå - Webblösningar för din affärstillväxt"
        />
        <meta
          property="og:description"
          content="SAIGAH Webbyrå specialiserar sig på att utveckla hemsidor, innovativ marknadsföring och avancerade AI-chatbots för att driva din affärstillväxt. Vi kombinerar teknisk expertis och kreativa lösningar för att skapa en unik digital närvaro som engagerar och konverterar."
        />
        <meta property="og:image" content="https://saigah.com/logo192.png" />
        <meta property="og:url" content="https://saigah.com" />

        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="SAIGAH" />

        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content="SAIGAH Webbyrå - Webblösningar för din affärstillväxt"
        />
        <meta
          name="twitter:description"
          content="SAIGAH Webbyrå specialiserar sig på att utveckla hemsidor, innovativ marknadsföring och avancerade AI-chatbots för att driva din affärstillväxt. Vi kombinerar teknisk expertis och kreativa lösningar för att skapa en unik digital närvaro som engagerar och konverterar."
        />
        <meta name="twitter:image" content="https://saigah.com/logo192.png" />

        <link rel="canonical" href="https://saigah.com" />

        <meta name="robots" content="index, follow" />

        <meta name="author" content="SAIGAH" />
        <meta name="publisher" content="SAIGAH" />
        <link rel="icon" href="https://saigah.com/favicon.ico" />

        <meta name="al:ios:url" content="app://saigah/" />
        <meta name="al:android:url" content="app://saigah/" />

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: "Home",
            description:
              "SAIGAH Webbyrå specialiserar sig på att utveckla hemsidor, innovativ marknadsföring och avancerade AI-chatbots för att driva din affärstillväxt. Vi kombinerar teknisk expertis och kreativa lösningar för att skapa en unik digital närvaro som engagerar och konverterar.",
            url: "https://saigah.com",
          })}
        </script>
      </Helmet>
      <Menu />
      <Banner scrollToProjects={scrollToProjects} /> <ServicesSection />
      <Projects ref={projectsRef} />
      <ContactForm />
      <BlogPostSection />
      <GrowthSection />
      <Footer />
      <ScrollToTopButton />
    </div>
  );
};

export default HomePage;
