import React, { useState, useEffect, useRef } from "react";
import Menu from "./menu";
import styles from "./contact.module.css";
import Footer from "./footer";
import { useTranslation } from "react-i18next";
import {
  FaEnvelope,
  FaPhone,
  FaMapMarkerAlt,
  FaTimes,
  FaArrowDown,
} from "react-icons/fa";
import emailjs from "@emailjs/browser";
import { Helmet } from "react-helmet";
import ScrollToTopButton from "./ScrollToTop.js";

function Banner() {
  const { t } = useTranslation();

  return (
    <div className={styles["top-banner"]}>
      <div className={styles["background-color"]}></div>
      <div className={styles["content-wrapper"]}>
        <div className={styles["heading-container"]}>
          <h1 className={styles["banner-heading"]}>{t("contact")}</h1>
          <h2 className={styles["banner-heading1"]}>{t("eager_to_learn")}</h2>
        </div>
      </div>
      <div className={styles["arrow-container"]}>
        <FaArrowDown className={styles["arrow-down"]} />
      </div>
    </div>
  );
}

function ContactForm() {
  const { t } = useTranslation();
  const form = useRef();
  const [showPopup, setShowPopup] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);

  const sendEmail = (e) => {
    e.preventDefault();
    if (!isChecked) {
      setIsFormValid(false);
      return;
    }
    emailjs
      .sendForm(
        "service_s26r9jm",
        "template_uub3hwm",
        form.current,
        "NBtLGn3Nhce3x_8qe"
      )
      .then(
        (result) => {
          setShowPopup(true);
          setTimeout(() => setShowPopup(false), 5000);
          form.current.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setIsFormValid(true);
  };

  return (
    <div>
      <h1 className={styles.title}>{t("share_your_thoughts")}</h1>
      <p className={styles.title2}>{t("eager_to_learn1")}</p>
      <form className={styles.form} ref={form} onSubmit={sendEmail}>
        <div className={styles.row}>
          <div className={styles.inputGroup}>
            <input
              className={styles.input}
              type="text"
              name="user_name"
              placeholder={t("full_name")}
              required
            />
          </div>
          <div className={styles.inputGroup}>
            <input
              className={styles.input}
              type="text"
              name="user_organization"
              placeholder={t("company_organization")}
              required
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.inputGroup}>
            <input
              className={styles.input}
              type="tel"
              name="user_phone"
              placeholder={t("phone")}
              required
            />
          </div>
          <div className={styles.inputGroup}>
            <input
              className={styles.input}
              type="email"
              name="user_email"
              placeholder={t("email")}
              required
            />
          </div>
        </div>
        <div className={styles.textareaGroup}>
          <textarea
            className={styles.textareaInput}
            name="message"
            placeholder={t("message")}
            required
          />
        </div>
        <div className={styles.checkboxContainer}>
          <input
            type="checkbox"
            id="consentCheckbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
            className={styles.checkbox}
          />
          <label htmlFor="consentCheckbox">
            I agree to the data processing according to the{" "}
            <a href="/gdprpage">GDPR policy</a>.
          </label>
        </div>
        {!isFormValid && (
          <div className={styles.errorMessage}>
            You must agree to the privacy policy.
          </div>
        )}
        <div className={styles.inputGroup} style={{ textAlign: "center" }}>
          <input className={styles.submit} type="submit" value={t("submit")} />
        </div>
      </form>
      {showPopup && (
        <div className={`${styles.popup} ${styles.fadeIn}`}>
          <p className={styles.popupMessage}>Message sent!</p>
          <FaTimes
            className={styles.closeIcon}
            onClick={() => setShowPopup(false)}
          />
        </div>
      )}
    </div>
  );
}

function ContactDetails() {
  const { t } = useTranslation();
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div className={styles.contactDetails}>
      <h2>{t("contact_information")}</h2>
      <div className={styles.detailItem}>
        <FaEnvelope className={styles.icon} />
        <span>info@SAIGAH.com</span>
      </div>
      <div className={styles.detailItem}>
        <FaPhone className={styles.icon} />
        <span>+46 727 202 106</span>
      </div>
      <div className={styles.detailItem}>
        <FaMapMarkerAlt className={styles.icon} />
        <span>Stockholm/Sundsvall, SE</span>
      </div>
    </div>
  );
}

function MAP() {
  const { t } = useTranslation();

  return (
    <div className={styles.mapContainer}>
      <div className={styles.mapResponsive}>
        <iframe
          title="location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d130265.83887425221!2d17.817248407020944!3d59.32621310815854!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f763119640bcb%3A0xa80d27d3679d7766!2sStockholm!5e0!3m2!1sen!2sse!4v1717254257037!5m2!1sen!2sse"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
}

function ContactSection() {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles["about-us-container"]}>
        <div className={styles["block"]}>
          <ContactForm />
          <div className={styles.detailsFaqContainer}>
            <ContactDetails />
            <MAP />
          </div>
        </div>
      </div>
    </div>
  );
}

function Contact() {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }

    setIsLoaded(true);
  }, []);

  if (!isLoaded) {
    return null;
  }

  return (
    <div>
      <Helmet>
        <title>Kontakta oss</title>
        <meta
          name="description"
          content="Kontaktuppgifter. SAIGAH; +46 727 202 106; info@saigah.se; Stockholm/Sundsvall, SE"
        />
        <meta
          name="keywords"
          content="contact, get in touch, inquiries, kontakt, komma i kontakt, förfrågningar"
        />
        <meta property="og:title" content="Kontakta oss" />
        <meta
          property="og:description"
          content="Kontaktuppgifter. SAIGAH; +46 727 202 106; info@saigah.se; Sundsvall, SE"
        />
        <meta property="og:image" content="https://saigah.com/logo192.png" />
        <meta property="og:url" content="https://saigah.com/contact" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="SAIGAH" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="Kontakta oss" />
        <meta
          name="twitter:description"
          content="Kontaktuppgifter. SAIGAH; +46 727 202 106; info@saigah.se; Sundsvall, SE"
        />
        <meta name="twitter:image" content="https://saigah.com/logo192.png" />
        <link rel="canonical" href="https://saigah.com/contact" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="SAIGAH" />
        <meta name="publisher" content="SAIGAH" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="https://saigah.com/favicon.ico" />
        <meta name="al:ios:url" content="app://saigah/contact" />
        <meta name="al:android:url" content="app://saigah/contact" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            name: "Kontakta oss",
            description:
              "Ta kontakt med SAIGAH via vår kontaktsida. Kontakta oss för förfrågningar, support eller feedback.",
            url: "https://saigah.com/contact",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+46 727 202 106",
                contactType: "Customer Support",
                email: "info@saigah.com",
              },
            ],
          })}
        </script>
      </Helmet>
      <Menu />
      <Banner />
      <ContactSection />
      <Footer />
      <ScrollToTopButton />
    </div>
  );
}

export default Contact;
