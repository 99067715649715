import React, { useRef, useState } from "react";
import styles from "./ContactForm.module.css";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";
import { FaTimes } from "react-icons/fa";

const ContactForm = () => {
  const { t } = useTranslation();
  const form = useRef();
  const [showPopup, setShowPopup] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);

  const sendEmail = (e) => {
    e.preventDefault();

    if (!isChecked) {
      setIsFormValid(false);
      return;
    }

    emailjs
      .sendForm(
        "service_s26r9jm", // Replace with your Service ID
        "template_uub3hwm", // Replace with your Template ID
        form.current,
        "NBtLGn3Nhce3x_8qe" // Replace with your Public Key
      )
      .then(
        (result) => {
          setShowPopup(true);
          setTimeout(() => setShowPopup(false), 5000);
          form.current.reset();
          setIsChecked(false);
          setIsFormValid(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setIsFormValid(true);
  };

  return (
    <div className={styles.my5}>
      <div className={styles.container}>
        <div
          className={`${styles.row} ${styles.dFlex} ${styles.alignItemsStretch}`}
        >
          <div className={`${styles.col} ${styles.lg6} ${styles.firstCol}`}>
            <div className={styles.cardShadow}>
              <div
                className={`${styles.cardBody} ${styles.dFlex} ${styles.flexColumn}`}
              >
                <h3 className={styles.cardTitle1}>Vart finns vi?</h3>
                <p className={styles.cardText1}>
                  Stockholm/Sundsvall, SE
                  <br />
                  info@saigah.com
                  <br />
                  +46 727 202 106
                </p>
                <div className={styles.mapResponsive}>
                  <iframe
                    title="location"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d130265.83887425221!2d17.817248407020944!3d59.32621310815854!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f763119640bcb%3A0xa80d27d3679d7766!2sStockholm!5e0!3m2!1sen!2sse!4v1717254257037!5m2!1sen!2sse"
                    style={{ border: 0 }}
                    width="800"
                    height="600"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.col} ${styles.lg6}`}>
            <div className={`${styles.h100} ${styles.cardShadow2}`}>
              <div
                className={`${styles.cardBody} ${styles.dFlex} ${styles.flexColumn}`}
              >
                <h3 className={styles.cardTitle}>Hör av dig till oss!</h3>
                <p className={styles.cardText}>
                  Använd gärna formuläret nedan för att kontakta oss. Vi
                  återkommer till dig så snart som möjligt.
                </p>
                <form ref={form} onSubmit={sendEmail}>
                  <div className={`${styles.formGroup} ${styles.mb3}`}>
                    <input
                      type="text"
                      placeholder="Ditt namn"
                      className={styles.formControl}
                      name="user_name"
                      required
                    />
                  </div>
                  <div className={`${styles.formGroup} ${styles.mb3}`}>
                    <input
                      type="text"
                      placeholder="Företag"
                      className={styles.formControl}
                      name="user_organization"
                      required
                    />
                  </div>
                  <div className={`${styles.formGroup} ${styles.mb3}`}>
                    <input
                      type="email"
                      placeholder="E-post"
                      className={styles.formControl}
                      name="user_email"
                      required
                    />
                  </div>
                  <div className={`${styles.formGroup} ${styles.mb3}`}>
                    <input
                      type="tel"
                      placeholder="Telefonnummer"
                      className={styles.formControl}
                      name="user_phone"
                      required
                    />
                  </div>
                  <div className={`${styles.formGroup} ${styles.mb3}`}>
                    <textarea
                      rows={2}
                      placeholder="Meddelande"
                      className={styles.formControl}
                      name="message"
                      required
                    ></textarea>
                  </div>

                  <div className={styles.checkboxContainer}>
                    <input
                      type="checkbox"
                      id="consentCheckbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      className={styles.checkbox}
                    />
                    <label htmlFor="consentCheckbox">
                      I agree to the data processing according to the{" "}
                      <a href="/gdprpage">GDPR policy</a>.
                    </label>
                  </div>
                  {!isFormValid && (
                    <div className={styles.errorMessage}>
                      You must agree to the privacy policy.
                    </div>
                  )}

                  <div style={{ textAlign: "center" }}>
                    <input
                      className={styles.submit}
                      type="submit"
                      value={t("submit")}
                    />
                  </div>
                </form>
                {showPopup && (
                  <div className={`${styles.popup} ${styles.fadeIn}`}>
                    <p className={styles.popupMessage}>Message sent!</p>
                    <FaTimes
                      className={styles.closeIcon}
                      onClick={() => setShowPopup(false)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
